.info {
    &-container {
        display: flex;
        column-gap: 12px;
        padding: 20px 16px;
    }

    &-title {
        color: #171717;

        letter-spacing: normal;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        max-width: 209px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-count-price {
        &-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 6px;
        }
    }

    &-count {
        color: rgba(23.38, 23.38, 23.38, 0.5);
        font-size: 14px;

        letter-spacing: normal;
        font-weight: 500;
        line-height: 19.6px;

        display: flex;
        justify-content: center;
        align-items: center;

        height: 20px;
        width: 64px;
        border: 1px solid #e8e8e8;
        border-radius: 16px;

        cursor: pointer;
    }

    &-price {
        font-size: 16px;
        color: #c5c5c5;

        letter-spacing: normal;
        font-weight: 600;
        line-height: 19.84px;
        margin: 0;
    }

    &-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 92px;
        height: 92px;
        flex-shrink: 0;

        .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin: 0 auto;
            border: 1px solid #eaeaea;
            border-radius: 8px;
        }
    }

    &_wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header-title {
        display: flex;
        justify-content: space-between;
    }

    &__sizes {
        display: flex;
        column-gap: 6px;
        font-size: 10px;
        overflow: hidden;
    }
}

.CounterControls {
    border-radius: 8px;
    padding: 12px;
    background-color: white;

    & > button {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.PriceCrossed {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 118%;
    color: rgba(23, 23, 23, 0.25);
    text-decoration: line-through;
    white-space: nowrap;
}

.MainPrice {
    color: #171717;

    letter-spacing: normal;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.LevelWrapper {
    display: flex;
    align-items: center;
    font-weight: 800;
    cursor: pointer;
}

.QuestionMark {
    padding-top: 2px;
    padding-left: 2px;
}

.LevelText {
    letter-spacing: normal;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 118%;
    color: #ff4b2b;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    word-wrap: normal;
}

.TooltipContent {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 144px;

    &_text {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 132%;
    }
}

.count {
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 105px;
        height: 36px;
        border-radius: 8px;
        background-color: #f2f2f2;

        letter-spacing: normal;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        color: var(--text-50, rgba(23, 23, 23, 0.5));
    }

    &__plus,
    &__minus {
        display: flex;
        cursor: pointer;
    }
}

.remove-item {
    &__container {
        position: absolute;
        background: linear-gradient(
            180deg,
            #fff 0%,
            rgba(255, 255, 255, 0.82) 83.41%,
            rgba(255, 255, 255, 0) 100%
        );
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        row-gap: 16px;
    }

    &__title {
        letter-spacing: normal;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 124%;

        margin: 0;
        max-width: 307px;

        text-align: center;
    }

    &__buttons {
        display: flex;
        column-gap: 12px;
    }

    &__close_button {
        letter-spacing: normal;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 132%;
        width: auto;
    }

    &__save_button {
        letter-spacing: normal;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 132%;
        width: auto;
    }
}
