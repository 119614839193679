.info {
    &-container {
        display: flex;
        column-gap: 12px;
        position: relative;
        padding: 20px 16px;
    }

    &-title {
        color: #171717;

        letter-spacing: normal;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        max-width: 209px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-count-price {
        &-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 6px;
        }
    }

    &-count {
        color: rgba(23.38, 23.38, 23.38, 0.5);
        font-size: 14px;

        letter-spacing: normal;
        font-weight: 500;
        line-height: 19.6px;

        display: flex;
        justify-content: center;
        align-items: center;

        height: 20px;
        width: 64px;
        border: 1px solid #e8e8e8;
        border-radius: 16px;

        cursor: pointer;
    }

    &-price {
        font-size: 16px;
        color: #c5c5c5;

        letter-spacing: normal;
        font-weight: 600;
        line-height: 19.84px;
        margin: 0;
    }

    &-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 92px;
        height: 92px;
        flex-shrink: 0;

        .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            margin: 0 auto;
            border: 1px solid #eaeaea;
            border-radius: 8px;
        }
    }

    &_wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__header-title {
        display: flex;
        justify-content: space-between;
    }
}

.CounterControls {
    border-radius: 8px;
    padding: 12px;
    background-color: white;

    & > button {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.PriceCrossed {
    font-style: normal;
    font-weight: 600;
    line-height: 124%;
    color: rgba(23, 23, 23, 0.25);
    text-decoration: line-through;
    white-space: nowrap;
    font-size: 10px;
}

.MainPrice {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
    letter-spacing: normal;
    color: rgba(23, 23, 23, 0.25);
    text-decoration: line-through;
}

.LevelText {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 124%;
    color: #ff4b2b;
}

.count {
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 105px;
        height: 36px;
        border-radius: 8px;
        background-color: #f2f2f2;

        letter-spacing: normal;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        color: var(--text-50, rgba(23, 23, 23, 0.5));
    }

    &__plus,
    &__minus {
        display: flex;
        cursor: pointer;
    }
}

.trade_dialog {
    padding: 0 17px;
    position: absolute;
    z-index: 1;
    bottom: 140px;

    &::before {
        content: '';
        position: absolute;
        bottom: -10px;
        /* Adjust this value if you change border width */
        left: 6%;
        transform: translateX(-50%);
        rotate: 180deg;
        z-index: 0;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 16px solid white;
        /* This is the color of the arrow, which should match the dialog background */
    }

    &_container {
        background: white;
        display: flex;
        padding: 24px 0px 16px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        border-radius: 24px;
        box-shadow: -3px 0px 25px 0px rgba(0, 0, 0, 0.25);
        max-width: 327px;
    }

    &_title {
        display: flex;
        padding: 0px 16px;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        align-self: stretch;

        &_smile {
            text-align: center;

            letter-spacing: normal;
            font-size: 38px;
            font-style: normal;
            font-weight: 600;
            line-height: 116%;
            letter-spacing: normal;
        }

        &_status-info {
            color: #000;
            text-align: center;

            letter-spacing: normal;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 132%;
            letter-spacing: normal;
        }

        &_info {
            text-align: center;

            letter-spacing: normal;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: normal;
        }

        &_hint_bold {
            letter-spacing: normal;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            letter-spacing: normal;
        }

        &_hint_colored {
            color: #fcba0f;

            letter-spacing: normal;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            letter-spacing: normal;
        }
    }

    &_input_wrapper {
        position: relative;
        display: inline-block;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &_input {
        padding: 16px 60px 16px 16px;
        background: #f2f2f2;
        border-radius: 8px;
        border: 0;
        outline: none;
        width: 295px;

        letter-spacing: normal;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 132%;
        letter-spacing: normal;

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type='number'] {
            -moz-appearance: textfield;
        }

        &:focus {
            outline-style: none;
            box-shadow: none;
        }

        &:disabled {
            pointer-events: none;
            opacity: 0.7;
        }

        &_error {
            border: 2px solid #c61a1a;
        }

        &__currency {
            position: absolute;
            right: 22px;
            top: 16px;
            font-family: Montserrat;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: rgba(23, 23, 23, 0.5);
        }
    }

    &_button-wrapper {
        padding: 0 16px;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
    }
}

.warning {
    color: #ff4b2b;

    letter-spacing: normal;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: normal;

    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
}

.trade_btn {
    &:disabled {
        background: #f2f2f2;
        border-color: #f2f2f2;
        color: rgba(23, 23, 23, 0.25);
    }
}

.spinner {
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 22px;
    top: 16px;

    &_spin {
        display: block;

        &::after {
            content: '';
            display: block !important;
            position: absolute;
            top: 0px;
            left: 10px;
            width: 3px;
            height: 6px;
            border-radius: 20%;
            background: #ff4b2b;
        }

        &:nth-child(1) {
            transform: rotate(0deg);
            animation-delay: 0s;
        }

        &:nth-child(2) {
            transform: rotate(45deg);
            animation-delay: 0.125s;
        }

        &:nth-child(3) {
            transform: rotate(90deg);
            animation-delay: 0.25s;
        }

        &:nth-child(4) {
            transform: rotate(135deg);
            animation-delay: 0.375s;
        }

        &:nth-child(5) {
            transform: rotate(180deg);
            animation-delay: 0.5s;
        }

        &:nth-child(6) {
            transform: rotate(225deg);
            animation-delay: 0.625s;
        }

        &:nth-child(7) {
            transform: rotate(270deg);
            animation-delay: 0.75s;
        }

        &:nth-child(8) {
            transform: rotate(315deg);
            animation-delay: 0.875s;
        }

        transform-origin: 12px 12px;
        animation: spinner 1.2s linear infinite;
    }
}

@keyframes spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.error_message {
    color: #c61a1a;
    text-align: center;

    letter-spacing: normal;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
    letter-spacing: normal;
    margin-top: 8px;
}

.hint_main_btn {
    &:hover {
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
            ),
            linear-gradient(#ff4b2b, #ff4b2b);
    }

    &:active {
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.3)
            ),
            linear-gradient(#ff4b2b, #ff4b2b);
    }
}

.tradeButton {
    letter-spacing: normal;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    width: auto;
}
