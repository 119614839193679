@keyframes fadeInUp {
    from {
        bottom: 0px;
        opacity: 0;
    }

    to {
        bottom: 176px;
        opacity: 1;
    }
}

@keyframes fadeOutDown {
    from {
        bottom: 176px;
        opacity: 1;
    }

    to {
        bottom: 0px;
        opacity: 0;
    }
}

.update_lvl_info {
    padding: 8px 12px 9px 12px;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    border-radius: 12px 12px 12px 0px;

    position: absolute;
    bottom: 0px;
    left: 16px;
    color: white;
    animation: fadeInUp 500ms ease forwards,
        fadeOutDown 500ms ease 1500ms forwards;

    &__increase {
        background-color: #1ac655;
    }

    &__decrease {
        background-color: #c61a1a;
    }
}
