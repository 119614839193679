.Modal {
    border-radius: 16px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
    padding: 20px;
    background-color: #ffffff;
    max-width: 80%;

    &__controls {
        display: flex;
        margin-top: 24px;
        justify-content: center;

        & > * {
            margin-right: 16px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.Content {
    position: relative;
}

.Title {
    margin-bottom: 8px;
}

.Modal__close {
    background: transparent;
    outline: none;
    border: none;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}
