.ErrorPage {
    border: 3px dashed var(--app-main-color);
    border-radius: 4px;
    position: relative;
    text-align: center;
    overflow: hidden;
    color: var(--app-main-color);
    padding: 8px;

    &__error {
        font-size: 80px;
        line-height: 96px;
    }
}
