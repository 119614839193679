.SuperadminAuth {
    &__container {
        max-width: 300px;
        margin: auto;
        margin-top: 200px;
    }

    &__btn {
        background-color: #0e0e0e;
        border-color: #0e0e0e;

        &:hover,
        &:active {
            background-color: #696969;
            border-color: #696969;
        }
    }
}

.Shop {
    &__header {
        position: sticky;
        top: 0;
        z-index: 100;
        width: 100%;
        background-color: #ffffff;
        border-bottom: 1px solid #000000;
        padding: 0 20px;
        height: auto;
        display: flex;
        align-items: center;

        & > * {
            margin-right: 16px;
        }

        & > *:last-child {
            margin-left: auto;
        }
    }

    &__content {
        background-color: #ffffff;
        padding: 10px 30px 30px 30px;

        &_desc {
            color: #939393;
            font-size: 0.9em;
        }

        th {
            overflow-wrap: anywhere !important;
        }
    }
}

.PricesTable {
    border-collapse: collapse;
    table-layout: fixed;
    td,
    th {
        border-left: 1px solid #000;
        padding: 0 8px;
        &:first-of-type {
            border-left: none;
        }
    }
}

.paragraphEllipsis {
    display: inline-flex;
    margin-bottom: 0;
}

.cartIdCell {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
    text-overflow: ellipsis;
}
x .statsList {
    padding-left: 0;
    padding-top: 5px;
    list-style: none;
}

:global {
    .ant-table {
        table {
            height: 100%;
        }
    }
    .cell-green {
        background-color: rgba(0, 255, 0, 0.1);
    }
    .simple-table {
        .ant-table-tbody > tr > td {
            padding: 0 8px;
        }
        div.ant-typography,
        .ant-typography p {
            margin-bottom: 0;
        }
        .ant-table-tbody > tr > td {
            border-bottom: 1px solid #000;
            border-left: 1px solid #000;
            transition: background 0.3s;
        }
    }
}
