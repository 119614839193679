.Button {
    display: flex;
    max-width: 343px;
    height: 48px;
    border: 1px solid #ff4b2b;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    background-color: #ff4b2b;
    width: 100%;
    color: #fff;
    text-decoration: none;
    padding: 0 8px;
    // font-size: 18px;
    border-radius: 8px;
    white-space: break-spaces;
    text-align: center;

    letter-spacing: normal;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: normal;

    &:active,
    &:hover,
    &:focus {
        cursor: pointer;
        outline: none;
        color: #ffffff;
    }

    &__icon {
        display: flex;
        margin-right: 11px;
    }

    &_small {
        height: 32px;
        max-width: 200px;
        font-size: 12px;
    }

    &_ghost {
        background-color: transparent;
        color: #0e0e0e;

        &:active,
        &:hover,
        &:focus {
            color: #0e0e0e;
        }
    }

    &_green {
        background-color: #1ac655;
        color: #fff;
        border: 1px solid #1ac655;
    }

    &_black {
        background-color: #0e0e0e;
        color: #fff;
        border: 1px solid #0e0e0e;
    }

    &_light {
        background-color: #ffffff;
        color: #000000;
        border: 1px solid #1ac655;
        border-radius: 8px;

        &:active,
        &:hover,
        &:focus {
            color: #000000;
        }
    }

    &_lightGray {
        background: #f2f2f2;
        color: #171717;
        border: transparent;

        &:active,
        &:hover,
        &:focus {
            color: #fff;
            background-color: #ff4b2b;
        }
    }

    &_bonus {
        font-size: 20px;
        font-weight: 700;
        min-height: 40px;
        margin-top: auto;
        min-width: 100px;
        margin-left: auto;
        width: auto;
    }

    &:disabled {
        opacity: 0.3;
    }

    &_disabled {
        background-color: #bbbbbb;
        border: 1px solid #bbbbbb;
    }
}
