.Settings {
    text-align: center;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;

    & > div {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__Header {
        position: relative;
        font-size: 18px;
        padding-bottom: 16px;

        &_CloseBtn {
            position: absolute;
            right: 0;
            padding: 0 10px;
            border: none;
            background: none;
            outline: none;
        }
    }

    &__Content {
        display: flex;
        flex-direction: column;

        & > *:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__Footer {
        position: sticky;
        bottom: 0;
        padding-top: 10px;
        background: white;
        border: 2px solid white;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &_divider {
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background: #939393;
                margin-bottom: 20px;
            }
        }

        &_input {
            margin-top: 30px;
        }

        svg {
            width: 18px;
            height: 18px;
            transform: rotate(180deg);

            path {
                fill: #ffffff;
            }
        }
    }
}
