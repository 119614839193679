.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #ff4b2b;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ff4b2b transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    80% {
        transform: rotate(350deg);
    }

    90% {
        transform: rotate(340deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loaderPercentageIcon {
    animation: rotateAnimation 1s infinite ease;
}

.loading-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-text {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 128%;
        max-width: 198px;
        text-align: center;
    }
}
