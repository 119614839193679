.buy-container-wrapper {
    margin-top: auto;
}

.Order {
    width: 100%;
    display: block;
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: white;
    box-shadow: 0px 2px 43px 0px rgba(34, 60, 80, 0.2);

    &__top {
        display: flex;
        justify-content: space-between;
    }
}

.OldPrice {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 118%;
    color: #c5c5c5;
    text-decoration: line-through;
}

.Price {
    color: #171717;
    text-align: left;

    letter-spacing: normal;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
}

.CurrentLvlInfo {
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 118%;
    color: #8b8b8b;

    &_flex {
        display: flex;
        flex-wrap: wrap;
    }

    &_Bold_Container {
        font-size: 10px;
        font-style: normal;
        font-weight: 800;
        line-height: 118%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &_Arrow {
        display: block !important;
        width: 16px;
        height: 16px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent #ff4b2b transparent;
    }
}

.Hint {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
    color: white;
    background: #ff4b2b;
    border-radius: 12px;
    padding: 12px;

    display: flex;
    align-items: center;
    column-gap: 12px;

    position: relative;
    top: -1px;

    &_AddProductsBtn {
        width: auto;
        white-space: nowrap;
        color: #ff4b2b;

        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 132%;
        padding: 8px 12px;

        &:hover {
            background-color: white;
            color: #ff4b2b;
        }
    }

    b {
        font-weight: 800;
    }

    i {
        color: #ff4b2b;

        letter-spacing: normal;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
    }
}

.BuyButton {
    width: auto;
    min-width: 118px;

    letter-spacing: normal;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;
    padding: 8px 12px;
    margin-top: 8px;
}

.AddButton {
    width: 100%;
    max-width: 100%;
    font-style: normal;
    font-weight: 600;

    letter-spacing: normal;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 132%;

    border-radius: 0px 0px 8px 8px;

    background-color: #ff4b2b;

    &:hover {
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
            ),
            linear-gradient(#ff4b2b, #ff4b2b);
    }

    &:active {
        background-image: linear-gradient(
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.3)
            ),
            linear-gradient(#ff4b2b, #ff4b2b);
    }
}
