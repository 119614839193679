.tooltip {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 24px;
    background-color: #171717;
    color: white;
    padding: 8px;
    border-radius: 12px;
    z-index: 100;

    &::after {
        content: '';
        position: absolute;
        top: -9px;
        /* Adjust this value to move the arrow up or down */
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #171717 transparent;
        transform: translateX(-50%);
    }
}
