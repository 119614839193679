.Header {
    color: #fff;
    position: absolute;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    z-index: 1;
}

.ScanCodeContainer {
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    background-color: #000;
    height: 100vh;
    flex: 1;
    display: flex;
    flex-direction: column;

    &__qrScanner {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
