.Price {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    color: #0e0e0e;

    &_green {
        color: var(--app-positive-color);
    }

    &_orange {
        color: var(--app-main-color);
    }

    &_crossed {
        color: #c9c9c9;
        text-decoration: line-through;
    }

    &_bold {
        font-weight: 600;
    }
}
