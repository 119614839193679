.Header {
    text-align: center;
    background: #fff;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 124%;
    letter-spacing: normal;
    color: #171717;
    margin-top: -15px;

    & > div {
        display: flex;

        h2 {
            margin-left: 20px;
        }
    }

    &__points {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #a6a6a6;
        width: 100%;
        margin-right: 34px;
        padding-top: 5px;
        position: relative;
        text-align: center;

        div {
            position: absolute;
            height: 18px;
            border-radius: 5px;
            background-color: #c9c9c9;

            &:first-child {
                z-index: 100;
            }

            &:nth-child(2) {
                width: 100%;
                background-color: #c9c9c9;
            }
        }

        span {
            z-index: 999;
            color: #ffffff;
            position: relative;
        }

        &_filled {
            div:first-child {
                background-color: var(--app-main-color);
            }

            svg {
                position: relative;
                top: 2px;

                path {
                    fill: var(--app-main-color);
                }
            }
        }
    }

    &_qrButton {
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        color: #7b7b7b;
        display: flex;
        align-items: center;

        svg {
            path {
                fill: #7b7b7b;
            }

            margin-right: 8px;
            width: 24px;
            height: 24px;
        }
    }

    &_closeBtn {
        svg {
            path {
                fill: #7b7b7b;
            }

            width: 14px;
            height: 14px;
        }
    }

    &_shadow {
        box-shadow: 0px 2px 2px rgb(0 0 0 / 20%), 0px 4px 25px rgb(0 0 0 / 25%);
        -webkit-box-shadow: 0px 2px 2px rgb(0 0 0 / 20%),
            0px 4px 25px rgb(0 0 0 / 25%);
        -moz-box-shadow: 0px 2px 2px rgb(0 0 0 / 20%),
            0px 4px 25px rgb(0 0 0 / 25%);
        min-width: 375px;
        max-width: 635px;
        text-align: center;
        position: fixed;
        z-index: 980;
        background: transparent;
        top: 0;
        height: 115px;
        padding-top: 16px;
        width: 100%;
    }

    &_shadowCover {
        max-width: 100%;
        position: fixed;
        z-index: 990;
        background: #ffffff;
        top: 0;
        left: 0;
        height: 115px;
        padding-top: 16px;
        width: 100%;
    }

    &_withoutFilter {
        height: 55px;
    }
}

.Menu {
    overflow: hidden;
    margin-top: 24px;

    &__inner {
        list-style: none;
        margin: 0;
        padding: 0 16px 0;
        display: flex;
        overflow: auto;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__item {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: rgba(14, 14, 14, 0.5);
        border-bottom: 1px solid transparent;
        padding: 7px 5px 8px;
        margin-right: 12px;

        a {
            color: inherit;
            text-decoration: none;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &_active {
            transition-duration: 0.5s;
            color: var(--app-main-color);
            border-bottom: 1px solid #0e0e0e;
        }
    }
}

.CatalogList {
    &__item {
        margin-bottom: 24px;
    }

    padding-bottom: 64px;
}

.CatalogItem {
    max-width: 158px;

    &__contentBlock {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__titleBlock {
        margin-bottom: 4px;
    }

    &__points {
        color: #ffffff;
        position: absolute;
        right: 8px;
        top: 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        background-color: var(--app-main-color);
        border-radius: 15px;
        padding: 4px 8px;
    }

    &__img {
        width: 158px;
        height: 200px;
        object-fit: contain;
    }

    &__title {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin: 0;
    }

    &__desc {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #a6a6a6;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
            margin-top: 8px;
        }
    }

    &__price div {
        font-weight: 600;
        font-size: 13px;
        white-space: nowrap;
    }

    &__btn {
        margin-top: 8px;
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        min-height: 38px;

        svg {
            path {
                fill: #000000;
            }
        }
    }
}

.Filter {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    height: 32px;

    &__sex {
        display: flex;
        align-items: center;
        position: relative;
        line-height: 16px;
        min-width: 40px;
        width: 50%;
        justify-content: center;
        border-radius: 16px;
        border: 1px solid #d6d6d6;
        font-weight: 600;
        font-size: 16px;

        &:first-child {
            margin-right: 8px;
        }

        &_selected {
            border-color: var(--app-main-color);
        }
    }

    &__sizes {
        flex-wrap: wrap;
        height: auto;
        margin-bottom: 10px;
        margin-top: -10px;
        width: 100%;
        grid-row-gap: 10px;

        &_item {
            padding-left: 4px;
            padding-right: 4px;
            display: flex;
            align-items: center;
            position: relative;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            height: 32px;
            justify-content: center;
            border: 1px solid #d6d6d6;
            border-radius: 4px;
            margin-right: 8px;
            min-width: 36px;

            &_selected {
                color: var(--app-main-color);
                border-color: var(--app-main-color);
            }
        }
    }

    &__more {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: var(--app-main-color);
        display: flex;
        align-items: center;
        padding: 0 8px;
        border: none;
    }
}

.CategoriesContainer {
    padding-bottom: 30px;

    &_withoutFilter {
        //
    }
}

.Category {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(156px, 1fr));
    justify-items: center;
    grid-gap: 16px;
    padding-top: 16px;
}

.Grid {
    padding-bottom: 120px;
}

.MonoCategory {
    padding-top: 85px;
    height: calc(100vh - 100px);

    &_withFooter {
        padding-top: 40px;
        height: calc(100vh - 230px);
    }
}

@supports (height: 70dvh) {
    .MonoCategory {
        height: calc(100dvh - 100px);

        &_withFooter {
            height: calc(100dvh - 230px);
        }
    }
}

.PointsWarn {
    p {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        margin: 0 20px;
    }

    img {
        width: 72px;
        height: 72px;
        object-fit: contain;
        margin: 0 auto;
        border: 1px solid #eaeaea;
        border-radius: 8px;
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-top: 30px;
        text-align: center;

        p {
            font-size: 14px;
            line-height: 14px;
            align-items: center;
            color: #a6a6a6;
        }

        &_price {
            font-weight: 600;
            font-size: 24px;
            line-height: 24px;
            padding: 12px 0 4px 0;
        }

        &_points {
            p {
                color: var(--app-main-color);
            }
        }
    }
}

.catalog {
    &__container {
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;
        padding: 16px;
    }

    &__catalog-items-container {
        overflow-y: auto;
        flex-grow: 1;
    }
}

.FilterButton {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    display: inline-flex;
    padding: 8px;
    padding-left: 0;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    position: relative;

    svg {
        margin-right: 4px;
    }
}

.ModalHeader {
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.CloseButton {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    margin-left: auto;
}

.FilterItem {
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }
}

.FilterStateButtonsBlock {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 12px;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.FilterStateButton {
    padding: 8px 12px 8px 12px;
    border-radius: 24px;
    gap: 4px;
    background-color: #f2f2f2;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    text-align: right;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 40px;
    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }
}

.FilterButtonActive {
    &:after {
        content: '';
        position: absolute;
        transform: translateY(-50%);
        width: 8px;
        height: 8px;
        background-color: var(--app-main-color);
        border-radius: 50%;
        right: -2px;
        top: 10px;
    }
}

.FilterCloseButton {
    position: absolute;
    right: 0;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    margin-left: auto;
    width: 40px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 10px;
        height: 10px;
    }
}

.qr {
    width: 44px;
    border-radius: 12px;
    margin-left: 10px;
    background: black;
    display: flex;
    align-items: center;
    width: 44px;
    justify-content: center;
    border: none;
    cursor: pointer;
}
