.CatalogItem {
    max-width: 156px;
    max-height: 351px;

    display: grid;
    grid-template-rows: 200px 1fr;
    height: 100%;

    &__monoContentBlock {
        margin-top: 8px;
        row-gap: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__contentBlock {
        max-width: 156px;
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__points {
        color: #ffffff;
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 118%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #ff4b2b;
        border-radius: 8px 8px 8px 0px;
        padding: 4px 8px;
    }

    &__img {
        width: 156px;
        height: 183px;
        object-fit: contain;
    }

    &__title {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin: 0;
    }

    &c {
        height: 63px;
        overflow: hidden;
    }

    &__titleBlock {
        max-height: 63px;
        overflow: hidden;
    }

    &__monoTitleBlock {
        max-height: 63px;
        overflow: hidden;
    }

    &__desc {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 96%;

        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 132%;
        letter-spacing: normal;
        color: rgba(23, 23, 23, 0.5);
    }

    &__descMono {
        margin: 0;

        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 132%;
        letter-spacing: normal;
        color: rgba(23, 23, 23, 0.5);
    }

    &__btn {
        border-radius: 8px;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 132%;
        color: #ff4b2b;
        border-color: #ff4b2b;
        padding: 8px 16px;

        &:hover {
            background-color: #ff4b2b;
        }

        &:active {
            background-color: #ff4b2b;
        }
    }

    &__sizes {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}

.Price {
    &__numbers {
        margin-bottom: 10px;
        min-height: 32px;

        display: flex;
        flex-direction: column;
        justify-content: end;
    }

    &__value_old {
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 118%;
        text-decoration: line-through;
        color: #c5c5c5;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__value_actual {
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 124%;
        color: #171717;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
