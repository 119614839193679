.Card {
    border: 2px solid #bbbbbb;
    border-radius: 4px;
    position: relative;
    overflow: hidden;

    &_green {
        border: 2px solid #1ac655;
    }

    &_red {
        border-color: var(--app-main-color);
    }

    &__inner {
        display: grid;
        padding: 8px;
        grid-template-columns: 100px 1fr 1fr;
        box-sizing: content-box;
    }

    &__img {
        height: 110px;
        max-width: 100px;
        object-fit: contain;
        margin: 0 auto;
        padding-right: 8px;
    }

    &__content {
        display: flex;
        width: 100px;
        flex-direction: column;
        justify-content: center;
        margin-right: 10px;
    }

    &__bonus {
        display: flex;
    }
}
