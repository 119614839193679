.dropdown {
    margin: 0 auto;
    width: 100%;
    position: relative;
}

.selected {
    width: 100%;
}

.dropdownBtn {
    border-radius: 12px;
    font-family: Montserrat;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 6px 10px 12px;
    color: #171717;
    background-color: #f2f2f2;
    font-weight: bold;
    font-size: 12px;
}
.dropdownContent {
    position: absolute;
    z-index: 10;
    /* top: 110%; */
    left: 0;
    width: 100%;
    padding: 4px 0px 4px 0px;
    border-radius: 8px;
    background-color: #fff;
    max-height: 300px;
    overflow: auto;
    box-shadow: -3px 0px 25px 0px #00000040;
}
.item {
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    font-family: Montserrat;
}
