.Header {
    display: grid;
    margin-bottom: 10px;
    row-gap: 16px;
}

.Seller {
    &__Btn {
        width: 100%;
        max-width: 100%;
        border-radius: 4px;

        &_qr {
            path {
                fill: white;
            }
        }
    }

    &__Phone {
        text-align: center;
        margin-top: 15px;
    }

    &__Results {
        text-align: center;

        p {
            color: var(--app-positive-color);
            font-size: 24px;
            font-weight: 700;
            margin: 0 0 8px 0;
        }

        button {
            margin: 0 auto;
        }

        div:first-child {
            display: flex;

            button:first-child {
                border-color: #000000;
                border-radius: 4px 0 0 4px;
            }

            button:last-child {
                border-color: #000000;
                border-radius: 0 4px 4px 0;
            }
        }

        &__divider {
            height: 1px;
            margin: 24px 0 8px 0;
            background-color: #c9c9c9;
            width: 100%;
        }
    }
}

.SellerItem {
    &::before {
        display: block;
        content: '';
        height: 1px;
        margin: 16px 0;
        background-color: rgba(#0e0e0e, 0.5);
    }

    &__time {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        & > div:first-child {
            color: rgba(14, 14, 14, 0.5);
        }
    }

    &__divider {
        &::before {
            display: block;
            content: '';
            height: 1px;
            margin: 16px 0;
            background-color: rgba(#0e0e0e, 0.5);
        }
    }

    &__total {
        display: flex;
        justify-content: center;
        margin: 0 8px 32px 8px;

        &_amount {
            & > div:first-child {
                display: inline-block;
            }

            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 120%;
            color: var(--app-positive-color);
        }
    }

    &__menu {
        display: grid;
        row-gap: 16px;
        margin-bottom: 16px;
    }

    &__addBtn {
        height: 120px;
        margin: 16px auto;
    }
}

.SellerAuth {
    align-items: center;
    justify-content: space-between;
    flex: 1;
    flex-direction: column;
    display: flex;

    &__title {
        font-size: 36px;
        line-height: 43px;
        text-align: center;
        margin-bottom: 0;
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        //bottom: 60px;

        label {
            font-size: 18px;
            line-height: 24px;
            //max-width: 343px;
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        input {
            padding: 10px;
            border: 1px solid #939393;
            border-radius: 4px;
            margin-bottom: 30px;
            margin-top: 10px;

            &:focus {
                outline: none;
            }
        }
    }
}

.Feedback {
    align-items: center;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;

    & > div {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        position: relative;
        font-size: 36px;
        margin-bottom: 22px;
        line-height: 43px;
        text-align: center;
        padding: 0 40px;
    }

    &__subTitle {
        font-size: 24px;
        margin-bottom: 30px;
    }

    textarea {
        width: 95%;
        margin: 10px 0 30px 0;
        padding: 10px;
        border-radius: 4px;

        &:focus {
            outline: none;
        }
    }
}
