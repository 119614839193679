.Wrapper {
    min-width: 375px;
    max-width: 667px;
    width: 100%;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    margin: auto;
    font-size: 18px;
    background-color: #fff;

    &_loading {
        transition-duration: opacity 0.5s;
        opacity: 0.5;
    }
}

.Title {
    flex: 1;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #0e0e0e;
    margin: 0;
    text-align: center;

    &_small {
        font-size: 16px;
        line-height: 22px;
        font-weight: normal;
    }
}

.SubTitle {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: rgba(14, 14, 14, 0.5);
}

.Desc {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: #0e0e0e;
    margin: 0;

    &_small {
        font-size: 12px;
        line-height: 16px;

        &_mt {
            margin-top: 12px;
        }
    }
}

.WarningIcon {
    width: 20px;
    height: 20px;
    border: 1px solid var(--app-main-color);
    border-radius: 50%;
    display: inline-flex;
    color: #0e0e0e;
    align-items: center;
    justify-content: center;

    &_green {
        border: 1px solid var(--app-positive-color);
    }
}

.Header {
    min-height: 56px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CloseBtn {
    background: transparent;
    border: none;
    position: absolute;
    outline: none;
    top: 15px;
    right: 5px;
    padding: 0 10px;
    cursor: pointer;

    svg {
        height: 24px;
        width: 24px;
    }
}

.BasketBtn {
    position: absolute;
    top: 15px;
    right: 13px;
    outline: none;
    border: none;
    background: transparent;
}

.Input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    caret-color: #939393;
    border: 1px solid #939393;
    padding: 10px 0 10px 10px;
    font-size: 36px;
    line-height: 43.2px;

    &:focus {
        outline: none;
    }
}

.LoadingCover {
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
}

.FullPage {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    background-color: #fff;
}

@supports (height: 100dvh) {
    .FullPage {
        height: 100dvh;
    }
}

.AdminPage {
    padding: 15px 20px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}
