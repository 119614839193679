.Container {
    max-width: 300px;
    margin: auto;
    margin-top: 200px;
}

.Controls {
    text-align: center;
}

.FormInput {
    max-width: 226px;
}
