.ItemImage {
    width: 100px;
}

.TableControls {
    padding: 8px 0;
    display: flex;
    align-items: center;
    & > * {
        margin-right: 16px;
    }
}

.FormRow {
    display: flex;
}

.FormCheckbox {
    margin-right: 16px;
    position: relative;
    top: 5px;
}

.ResetButton {
    margin-left: 8px;
}

.ModalSpin {
    margin-left: 16px;
}

.RemovePhoto {
    margin-left: 16px;
}

.PageControls {
    margin-left: auto;
}
.Link {
    margin: 0 10px;
}
.Button {
    margin: 0 5px;
}
