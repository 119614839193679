.close {
    z-index: 1;
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: relative;

    span {
        position: absolute;
        width: 80%;
        height: 2px;
        left: 10%;
        top: 45%;
        background-color: #7b7b7b;
    }

    .first {
        transform: rotate(45deg);
    }

    .last {
        transform: rotate(-45deg);
    }
}
