:root {
    --app-main-color: #ff4b2b;
    /*--app-main-color: #ff4b2b;*/
    --app-main-color-rgb: 255, 75, 43;
    --app-positive-color: #1ac655;
    --app-negative-color: #c61a1a;
}

.icon_main_color > path {
    fill: var(--app-main-color);
}

.icon_positive_color > path {
    fill: var(--app-positive-color);
}

.icon_negative_color > path {
    fill: var(--app-negative-color);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.notification-message .title {
    color: #fff;
}

#root {
    height: 100%;
}
