.CatalogInfoContainer {
    margin: -15px;
    margin-top: 0;
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background: white;
    box-shadow: 0px 2px 43px 0px rgba(34, 60, 80, 0.2);

    &__top_wrapper {
        display: flex;
        column-gap: 4px;
        justify-content: space-between;
    }

    &__lvl_info {
        width: 208px;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 132%;
        color: #171717;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        padding-top: 4px;

        &-current {
            color: #ff4b2b;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__arrow {
                display: block !important;
                width: 16px;
                height: 16px;
                border-width: 8px;
                border-style: solid;
                border-color: transparent transparent #ff4b2b transparent;
            }
        }
    }

    &__Hint {
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 132%;
        color: white;
        background: #ff4b2b;
        border-radius: 12px;
        padding: 12px;
        position: relative;
        top: -1px;
    }

    &__back_button {
        padding: 8px 12px;
        width: auto;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 132%;
        color: #171717;
        margin-left: auto;
    }
}
