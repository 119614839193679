.RadioInput {
    position: relative;
    cursor: pointer;

    & > span {
        position: absolute;
        top: 0;
        -moz-transform: translateY(14px);
        left: -30px;
        width: 24px;
        height: 24px;
        background-color: white;
        border-radius: 50%;
        transition: 0.3s background-color;
        border: 1px solid #d9d9d9;

        &:after {
            content: '';
            position: absolute;
            display: none;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: var(--app-main-color);
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }

        &:hover {
            border-color: var(--app-main-color);
        }
    }

    input[type='radio'] {
        margin-right: 8px;
        opacity: 0;
        width: 0;
        height: 0;
        display: none;

        &:checked ~ span {
            background-color: white;
            border-color: var(--app-main-color);

            &:after {
                display: block;
            }
        }
    }
}
