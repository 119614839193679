.cart {
    height: 100vh;
    box-shadow: -3px 0px 25px rgba(0, 0, 0, 0.25);
    min-width: 360px;
    max-width: 480px;
    margin: auto;
    @media (max-width: 445px) {
        width: 100%;
    }
    display: flex;
    flex-direction: column;
    position: relative;

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 10px 16px;
        align-items: center;

        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 124%;
        letter-spacing: normal;
        color: #171717;
    }

    &__close-and-lang-dropdown {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}

@supports (height: 100dvh) {
    .cart {
        height: 100dvh;
    }
}
