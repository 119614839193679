.Header {
    display: flex;
    justify-content: flex-end;
    padding: 28px 32px;
}

.CloseBtn {
    border: none;
    background: none;
    outline: none;
}

.Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    &__title {
        font-size: 36px;
        //margin-bottom: 74px;
        text-align: center;
        font-weight: 400;

        &_ascona {
            font-size: 26px;
            margin-top: 100px;
        }
    }

    &__subtitle {
        margin: 0 20px 20px;
        text-align: center;
    }
}

.QrCodeBlur {
    filter: blur(6px);
    -webkit-filter: blur(6px);
}

.SellerBtn {
    margin-top: auto;
}

.Loading {
    width: 160px;
    position: relative;
    margin: 0 !important;
}

.CartId {
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
}
