.Accordion {
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    &_fixed {
        padding-top: 52px;
    }
}

.accordion:nth-of-type(1) {
    border-top-width: 1px;
}

.AccordionTitle {
    font-size: 18px;
    line-height: 24px;
    color: #0e0e0e;
    display: flex;
    height: 44px;
    align-items: center;
    margin-bottom: 8px;
    justify-content: space-between;
    padding-right: 5px;
    text-align: left;

    &:last-of-type {
        margin-bottom: 0;
    }

    svg {
        flex-shrink: 0;
    }

    &_discount {
        color: var(--app-main-color);

        span {
            font-size: 16px;
            line-height: 16px;
            position: relative;
            top: -5px;
        }
    }
}

.AccordionTitleContainer {
    border-bottom: 1px solid rgba(14, 14, 14, 0.5);
    background-color: #fff;
    max-width: 635px;
    width: 100%;
    top: 0;
    position: sticky;
    z-index: 1000;
}

.AccordionTitle:hover {
    cursor: pointer;
}

.accordion img.toggle {
    width: 16px;
    height: 16px;
    align-self: center;
    margin-left: auto;
    transition: all 0.35s ease;
}

.accordion img.toggle[aria-expanded='true'] {
    transform: rotateZ(90deg);
}

.accordionContent {
    overflow: hidden;
    /*max-height: 1000px;*/
    transition: max-height 1s ease-in-out;
}

.accordionContent[aria-expanded='true'] {
    max-height: 0px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
