// Dropdown.module.scss
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.dropdown {
    position: relative;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
}

.dropdownContent {
    display: none;
    position: absolute;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: -3px 0px 25px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    // Initial state for animation
    opacity: 0;
    padding: 4px 0;
    left: -4px;

    a {
        color: black;
        padding: 8px 16px 8px 14px;
        text-decoration: none;
        display: block;
        border-radius: 4px;
        min-width: 70px;

        &:hover {
            background-color: #f1f1f1;
        }
    }
}

.dropdownButton {
    background-color: transparent;
    color: rgba(23, 23, 23, 0.5);
    border: none;
    cursor: pointer;
    // padding: 10px;
    font-weight: 600;

    &:after {
        content: '';
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
        border-top: 5px solid rgba(23, 23, 23, 0.5);
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
    }

    &:hover {
        background-color: #f8f8f8;
    }
}

.show {
    display: block;
    animation-name: fadeIn;
}

.hide {
    animation-name: fadeOut;
}
